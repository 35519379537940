<template>
  <v-sheet class="border-secondary rounded-xl pa-5 pa-sm-8" width="100%" max-width="500px">
    <v-img
      :src="getAdNetworkImages.theme_white_logo"
      :lazy-src="getAdNetworkImages.theme_white_logo"
      height="80"
      contain
      class="mx-auto mb-4"
    />
    <c-btn
      color="primary"
      :loading="isLoading"
      large
      block
      label="Confirm email"
      @click="confirmEmail()"
    />
  </v-sheet>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { mapGetters } from 'vuex'
  import personalRepository from '@/services/repositories/personal-repository.js'
  import handleErrors from '@/services/handleErrors.js'
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'ConfirmEmailPage',
    components: {
      CBtn
    },
    data() {
      return {
        isLoading: false
      }
    },
    computed: {
      ...mapGetters('settings', ['getAdNetworkImages'])
    },
    methods: {
      async confirmEmail() {
        this.isLoading = true
        const { userId, code } = this.$route.params
        try {
          await personalRepository.confirmEmail({ userId, code })
          const routeName = this.$auth.user ? routeNames.MAIN : routeNames.LOGIN
          await this.$router.push({ name: routeName })
        } catch (error) {
          handleErrors(error)
        }
        this.isLoading = false
      }
    }
  }
</script>
